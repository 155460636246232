import React, { useMemo } from "react";
import {
  Money,
  zero,
} from "@heritageholdings/lib-commons-finance/lib/units/money";
import { CashflowAssetJCurve } from "../../data/CashflowAssetJCurve";
import { CashflowJCurve } from "../../data/CashflowJCurve";
import {
  Table,
  TableAggregationModel,
  TableColumn,
  TableGroupingColumn,
} from "../../../core/v2/Table/Table";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import { Box } from "../../../core/v2/Box/Box";
import { datadogRum } from "@datadog/browser-rum";

type CashflowSimulatorTableRow = {
  id: string;
  path: Array<string>;
} & {
  [year: string]: Money | string | Array<string> | undefined;
};

type Props = {
  assetsJCurves: Array<CashflowAssetJCurve>;
  aggregatedJCurve: CashflowJCurve;
  disableVirtualization?: boolean;
};

/**
 * The Chashflow Simulator table component.
 */
export const CashflowSimulatorTable: React.FC<Props> = ({
  assetsJCurves,
  aggregatedJCurve,
}) => {
  const [columns, aggregationModel] = useMemo<
    [
      Array<TableColumn<CashflowSimulatorTableRow>>,
      TableAggregationModel<CashflowSimulatorTableRow>,
    ]
  >(() => {
    const columns: Array<TableColumn<CashflowSimulatorTableRow>> = [];
    const aggregationModel: TableAggregationModel<CashflowSimulatorTableRow> =
      {};

    if (!assetsJCurves.length) return [columns, aggregationModel];

    for (const year of Object.keys(aggregatedJCurve.cumulative)) {
      columns.push({
        field: year,
        headerName: year,
        type: "Money",
        align: "right",
        headerAlign: "right",
        sortable: false,
        valueFormatter: ({ value }) => {
          if (value instanceof Money)
            return U_Show.currencyThousandsNoDecimal("USD").show(
              value.toNormalizedAmount(),
            );

          return null;
        },
      });

      aggregationModel[year] = "moneySumAggregation";
    }

    return [columns, aggregationModel];
  }, [assetsJCurves, aggregatedJCurve]);

  const rows = useMemo<Array<CashflowSimulatorTableRow>>(() => {
    const totalRows: Array<CashflowSimulatorTableRow> = [];
    const aggregatedFundsSet = new Set<string>();

    assetsJCurves.forEach((asset) => {
      if (aggregatedFundsSet.has(asset.assetName)) {
        datadogRum.addError(
          new Error(
            `Duplicate fund in the Cashflow Simulator table: ${asset.assetName}`,
          ),
        );

        return;
      }

      const row: CashflowSimulatorTableRow = {
        path: ["Total Cash Flow", asset.assetName],
        id: `${asset.assetName}`,
      };

      for (const year of Object.keys(asset.distribution)) {
        const distributionVal = asset.distribution[year] ?? zero;
        const drawdownVal = asset.drawdown[year] ?? zero;

        row[year] = distributionVal?.plus(drawdownVal);
      }

      totalRows.push(row);
    });

    const cumulativeRow: CashflowSimulatorTableRow = {
      path: ["Cumulative Cash Flow"],
      id: "cumulative",
      ...aggregatedJCurve.cumulative,
    };

    return [cumulativeRow].concat(totalRows);
  }, [assetsJCurves, aggregatedJCurve]);

  const groupingColumn = useMemo<
    TableGroupingColumn<CashflowSimulatorTableRow>
  >(
    () => ({
      flex: 3,
      minWidth: 250,
      headerName: "",
    }),
    [],
  );

  return (
    <Box>
      <Table
        columns={columns}
        rows={rows}
        groupingColumn={groupingColumn}
        aggregationModel={aggregationModel}
        treeData
      />
    </Box>
  );
};
