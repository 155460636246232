import React, { createRef, useCallback, useMemo, useState } from "react";
import { PageTable } from "../../../core/v2/PageTable/PageTable";
import { TableColumn, tableFallbackValue } from "../../../core/v2/Table/Table";
import { Button } from "../../../core/v2/Button/Button";
import { Dialog } from "../../../core/v2/Dialog/Dialog";
import {
  BankAccountForm,
  BankAccountFormData,
  BankAccountFormRef,
} from "./BankAccountForm";
import {
  BankAccountStatus,
  useSubmitBankAccountFormMutation,
} from "../../../../generated/urql";
import { SingleBankAccount } from "../data/SingleBankAccount";
import { Box } from "../../../core/v2/Box/Box";
import { Typography } from "@mui/material";
import { useInvestor } from "../../../../hooks/useInvestor";
import { useNotifications } from "../../../../utils/notifications";
import { datadogRum } from "@datadog/browser-rum";

type BankAccountsRow = {
  id: string;
  accountDetails: string;
  bankName: string;
  status: string;
  createdAt: Date;
  currency: string;
};

function bankAccountToRow(
  ba: Omit<SingleBankAccount, "__typename">,
): BankAccountsRow {
  return {
    id: ba.id,
    bankName: ba.bankName,
    status: ba.status,
    createdAt: new Date(ba.createdAt),
    accountDetails:
      ba.iban ??
      `${ba.accountNumber ?? tableFallbackValue} (${ba.swift ?? tableFallbackValue})`,
    currency: ba.currency,
  };
}

type Props = {
  bankAccounts: ReadonlyArray<SingleBankAccount>;
};

export const BankAccounts: React.FC<Props> = ({ bankAccounts }) => {
  const [localBankAccounts, setLocalBankAccounts] = useState<
    Array<BankAccountsRow>
  >(bankAccounts.map(bankAccountToRow));
  const { errorNotification } = useNotifications();
  const investor = useInvestor();
  const formRef = createRef<BankAccountFormRef>();
  const [addBankAccountOpen, setAddBankAccountOpen] = useState(false);
  const [{ fetching }, mutate] = useSubmitBankAccountFormMutation();

  const cols = useMemo<Array<TableColumn<BankAccountsRow>>>(
    () => [
      {
        field: "bankName",
        headerName: "Bank Name",
      },
      {
        field: "accountDetails",
        headerName: "Account Details",
      },
      {
        field: "currency",
        headerName: "Currency",
      },
      {
        field: "status",
        headerName: "Status",
      },
      {
        field: "createdAt",
        headerName: "Created At",
        type: "date",
      },
    ],
    [],
  );

  const handleSubmit = useCallback(
    (data: BankAccountFormData) => {
      const investorId = investor?.id;

      if (!investorId) return;

      mutate(
        {
          input: {
            investorId,

            bankName: data.bankName,
            bankAddress: data.bankAddress,
            accountHolder: data.accountHolder,
            currency: data.currency,

            iban: data.iban,
            swift: data.swift,
            accountNumber: data.accountNumber,
          },
        },
        {},
      ).then(({ error, data: responseData }) => {
        if (error || !responseData?.submitInvestorBankAccountForm.success) {
          errorNotification("Could not add bank account, retry later");

          datadogRum.addError(error);
          return;
        }

        setLocalBankAccounts((current) => [
          ...current,
          bankAccountToRow({
            ...data,
            id: window.crypto.randomUUID(),
            status: BankAccountStatus.Pending,
            createdAt: new Date().toISOString(),
          }),
        ]);

        setAddBankAccountOpen(false);
      });
    },
    [mutate, investor, errorNotification],
  );

  const handleCloseModal = useCallback(() => {
    if (fetching) return;

    setAddBankAccountOpen(false);
  }, [fetching]);

  return (
    <>
      <PageTable
        title="Bank Accounts"
        description="In this section, you can enter the default bank account details for the selected investor. Upon submission, a member of our team will call the account owner to verify the bank details. Once approved, these details will be used for future capital distributions."
        kind="table"
        columns={cols}
        rows={localBankAccounts}
        customRightComponent={
          <Button variant="primary" onClick={() => setAddBankAccountOpen(true)}>
            Add Bank Account
          </Button>
        }
      />

      <Dialog
        open={addBankAccountOpen}
        title="Add Bank Account"
        onClose={handleCloseModal}
        submitButtonText="Submit"
        onSubmit={() => formRef.current?.triggerSubmit()}
        cancelButtonText="Cancel"
        onCancel={handleCloseModal}
        maxWidth={550}
        submitButtonDisabled={fetching}
      >
        <Box>
          <Typography whiteSpace="pretty">
            You are adding a bank account for the investor{" "}
            <strong>{investor?.businessName}</strong>.
          </Typography>
        </Box>

        <Box mt={2}>
          <BankAccountForm ref={formRef} onSubmit={handleSubmit} />
        </Box>
      </Dialog>
    </>
  );
};
