import React, { useMemo } from "react";

import { Money } from "@heritageholdings/lib-commons-finance/lib/units/money";

import { formatDateDisplay1 } from "../../../../utils/date";

import { PageTable } from "../../../core/v2/PageTable/PageTable";
import { moneySumAggregationLabel } from "../../../core/v2/Table/moneyTableUtilities";
import {
  TableColumn,
  TableGroupingColumn,
  tableFallbackValue,
} from "../../../core/v2/Table/Table";

import { SingleDistribution } from "../data/SingleDistribution";
import { formatAggregatedFundName, generateFundAggregationId } from "../utils";

type Props = Readonly<{
  distributions: ReadonlyArray<SingleDistribution>;
}>;

type DistributionsRow = {
  path: Array<string>;
  id: string;
  fundName: string;
  valueDate: Date;
  distributed: Money;
  isPaid: boolean;
  currency: string;
};

/**
 * The distributions section in the transactions page.
 */
export const Distributions: React.FC<Props> = ({ distributions }) => {
  const { rows, aggregatedFunds, fundAggregationIdToName } = useMemo(() => {
    const rows: Array<DistributionsRow> = [];
    const aggregatedFunds: Record<string, Set<string>> = {};
    const fundAggregationIdToName: Record<string, string> = {};

    distributions.forEach((distribution) => {
      const valueDate = distribution.valueDate;
      const distributionAggregationId = valueDate;

      if (aggregatedFunds[distributionAggregationId] === undefined)
        aggregatedFunds[distributionAggregationId] = new Set();

      // We are going to use this record to aggregate transactions with the same
      // fund and currency in this LP Distribution.
      const aggregatedTransactions: Record<string, DistributionsRow> = {};

      distribution.transactions.forEach((transaction) => {
        const fundName = transaction.allocation.asset.name;

        // FIXME: These types should be handled in a better way in order
        // to avoid the explicit cast.
        const currency = transaction.allocation.currency;

        const fundAggregationId = generateFundAggregationId(fundName, currency);

        const distributed = new Money({
          [currency]: transaction.amount,
        });

        const current: DistributionsRow | undefined =
          aggregatedTransactions[fundAggregationId];

        // If we already have a row with this fund and currency
        // then we just aggregate it to the existing value.
        if (current !== undefined) {
          current.distributed = current.distributed.plus(distributed);
          return;
        }

        // This will be used while rendering the single groups to make
        // the group name, which is composed by a comma separated list
        // of funds names for a specific capital call date.
        aggregatedFunds[distributionAggregationId].add(fundName);

        // This will be used while rendering each group item (leaf) in order
        // to avoid showing the aggregation fund id to the user.
        fundAggregationIdToName[fundAggregationId] = fundName;

        aggregatedTransactions[fundAggregationId] = {
          path: [distributionAggregationId, fundAggregationId],
          id: `${distributionAggregationId}-${fundAggregationId}`,
          fundName: fundName,
          valueDate: new Date(valueDate),
          distributed: distributed,
          isPaid: transaction.isPaid,
          currency,
        };
      });

      rows.push(...Object.values(aggregatedTransactions));
    });

    return { rows, aggregatedFunds, fundAggregationIdToName };
  }, [distributions]);

  const columns = useMemo<Array<TableColumn<DistributionsRow>>>(
    () => [
      {
        headerName: "Fund name",
        field: "fundName",
        flex: 1,
      },
      {
        headerName: "Value Date",
        field: "valueDate",
        type: "date",
        minWidth: 150,
        valueFormatter: ({ value, id, api }) => {
          if (!value || !id) return null;

          const row = api.getRowNode(id);

          // Hide the date if this is a leaf node.
          if (row?.type === "leaf") return null;

          return value instanceof Date
            ? formatDateDisplay1(value)
            : tableFallbackValue;
        },
        flex: 1,
      },
      {
        headerName: "Distributed",
        type: "Money",
        field: "distributed",
        flex: 1,
      },
    ],
    [],
  );

  const groupingColumn = useMemo<TableGroupingColumn<DistributionsRow>>(
    () => ({
      flex: 3,
      minWidth: 200,
      headerName: "Funds",
      valueFormatter: ({ value }) => {
        // If the value is not `undefined` then this is a
        // group and we should format it.
        if (typeof value !== "undefined") {
          return typeof value === "string" && aggregatedFunds[value]
            ? (formatAggregatedFundName(aggregatedFunds[value]) ??
                tableFallbackValue)
            : tableFallbackValue;
        }

        // If otherwise the value IS `undefined` then this is
        // a normal row, and we should return the base value.
        return value;
      },
      formatLeafValue: (value) =>
        typeof value === "string" ? fundAggregationIdToName[value] : value,
    }),
    [aggregatedFunds, fundAggregationIdToName],
  );

  return (
    <PageTable
      title="Distributions"
      kind="table"
      columns={columns}
      rows={rows}
      groupingColumn={groupingColumn}
      aggregationModel={{
        valueDate: "max",
        distributed: moneySumAggregationLabel,
      }}
      visibilityModel={{
        fundName: false,
      }}
      exportHidden
      treeData
      downloadExcel
    />
  );
};
