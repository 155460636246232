import React from "react";
import { DocumentsRouteComponent, FeatureFlag } from "../../generated/urql";
import { handleQueryData } from "../../utils/queries";
import { useInvestor } from "../../hooks/useInvestor";
import { DataRoomView } from "../../components/documents/v2/DataRoomView/DataRoomView";
import { useRecoilValue } from "recoil";
import { enableCapitalCallDocuments } from "../../state/developer";
import { useLayoutContext } from "../LayoutRoute/LayoutRoute";

export const DocumentsRoute: React.FC = () => {
  const investorId = useInvestor()?.id;
  const {
    authUser: { featureFlags },
  } = useLayoutContext();

  const capitalCallDocumentsLocalEnabled = useRecoilValue(
    enableCapitalCallDocuments,
  );

  const capitalCallDocumentsEnabledFlag = featureFlags.includes(
    FeatureFlag.EnableCapitalCallVdr,
  );

  const showCapitalCallDocuments =
    capitalCallDocumentsLocalEnabled || capitalCallDocumentsEnabledFlag;

  if (investorId === undefined) {
    return null; // TODO: handle error
  }

  return (
    <DocumentsRouteComponent
      variables={{
        investorId,
      }}
      children={handleQueryData((data) => (
        <DataRoomView
          capitalCalls={
            showCapitalCallDocuments ? data.investor.capitalCalls : undefined
          }
          documents={data.investor.documents}
        />
      ))}
    />
  );
};
