import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { SkeletonLoader } from "../../../core/v2/Loader/SkeletonLoader";
import { commonStatContainerProps } from "./PortfolioOverviewStats";

const statsBoxHeight = 133;

/**
 * The loader "skeleton" section for the portfolio top stats.
 */
export const PortfolioOverviewStatsLoader: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid {...commonStatContainerProps}>
        <SkeletonLoader size={{ height: statsBoxHeight }} />
      </Grid>

      <Grid {...commonStatContainerProps}>
        <SkeletonLoader size={{ height: statsBoxHeight }} />
      </Grid>

      <Grid {...commonStatContainerProps}>
        <SkeletonLoader size={{ height: statsBoxHeight }} />
      </Grid>

      <Grid {...commonStatContainerProps}>
        <SkeletonLoader size={{ height: statsBoxHeight }} />
      </Grid>
    </Grid>
  );
};
